import React, { createRef, useState, useEffect } from "react"
import { Link } from "gatsby"
import ArrowSubmit from "../../components/images/arrow-submit"
import TargetIcon from "../../components/images/target-icon"
import CalculatorForm from "./form"

import SEO from "../../components/seo"

//import CalculatorReport from "./report"


export default function CalculatePayment() {

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}
  useEffect(() => {
    addWebEvent("visit", "financial-calculator")
  },[]);



    //sessionStorage.setItem("formatted_address", ""); // set a variable

    const handleCompletion = value => {
      //dispatch({ payload: { [Field.Address]: value } })
      //dispatch({ type: Type.NextStep })
      //alert(JSON.stringify(value))
    }
    
    return (
    <>
      <SEO title="Calculator" />
      <div className="relative w-full text-center h-auto">
        {
          //relative w-full text-center overflow-x-scroll h-auto
        }
        <div className="absolute top-0 left-0 right-0 z-20 p-6 flex justify-between">
          <div>
            <button
              className="block focus:outline-none"
              //onClick={() => dispatch({ type: Type.PreviousStep })}
              style={{ transform: "rotate(180deg)" }}
            >
             
            </button>
          </div>
          <Link to="/" className={`cursor-pointer`}>
            <TargetIcon
              fill="white"
              rotate={true}
            ></TargetIcon>
          </Link>
        </div>
      </div>
      
      <div className={`flex-none w-full h-full flex flex-col px-6 justify-center items-center pt-12 pb-40 bg-green-900 text-white`}>
        {//flex-none w-full h-full flex flex-col px-6 justify-center items-center overflow-y-scroll pt-12 pb-40 bg-green-900 text-white
        }
        <br></br>
        <br></br>
        <h2 className="font-light tracking-wide mx-10">
          Let’s estimate <span className="font-bradford">Your Financing</span>
        </h2>
        <section id="calculator-form-section" className="relative h-full">
          <div className="max-w-lg lg:w-full">
            
            <CalculatorForm
              id="calculator-address"
              onAddressComplete={value => handleCompletion(value)}
              shouldNavigate={false}
              showIcon={true}
              showLoader={true}
              disabled_status={true}
            ></CalculatorForm>
          </div>
          <br></br>
          <small className="font-light tracking-wide text-gray-400 pt-16 text-center">
            The information you provide on this form will be used in accordance with
            our{" "}
            <Link className="underline" to="/privacy">
              privacy policy.
            </Link>{" "}
          </small>
          <br></br>
        </section>
      </div>
    </>

      //<CalculatorProvider>
        //<SEO title="Calculator" />
        //<CalculatorStepper

        //skipFirstStep={
          //location.state && !!location.state.formatted_address
        //}
          //address={location.state}

        //></CalculatorStepper>
      //</CalculatorProvider>

      //<QualifyProvider>
        //<SEO title="Qualify" />

        //<QualifyStepper
        //  skipFirstStep={location.state && !!location.state.formatted_address}
        //   address={location.state}
        //></QualifyStepper>
      //</QualifyProvider>
    )
  }