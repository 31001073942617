import React, { useState, useEffect } from "react"
import Script from "react-load-script"
import LoadingSpinner from "../../components/loading-spinner"
import './style.css'; //https://www.cssportal.com/style-input-range/ Amazing source

//CLEAN LOCAL STORAGE ON STARTUP
//if (typeof window !== 'undefined') {
//  sessionStorage.clear(); }

//SETUP DEFAULT VALUES ON START
const queryString = typeof window !== "undefined" ? window.location.search : ""
const urlParams = new URLSearchParams(queryString);

var property_value = urlParams.get("property_value"); // retrieve variable
if (property_value == null) { 
  var property_value = 520000
  if (typeof window !== 'undefined') {  
    sessionStorage.setItem("property-value-selector", property_value); // set a variable
  }
}
var mortgage_balance = urlParams.get("mortgage_balance"); // retrieve variable
if (mortgage_balance == null) { 
  var mortgage_balance = 250000
  if (typeof window !== 'undefined') {  
    sessionStorage.setItem("mortgage-balance-selector", mortgage_balance); // set a variable
  }
}
var income_level = urlParams.get("income_level"); // retrieve variable
if (income_level == null) {
  var income_level = 75000
  if (typeof window !== 'undefined') {  
    sessionStorage.setItem("household-income-selector", income_level); // set a variable 
  }
}
var credit_score = urlParams.get("credit_score"); // retrieve variable
if (credit_score == null) { 
  var credit_score = 700
  if (typeof window !== 'undefined') {  
    sessionStorage.setItem("credit-score-selector", credit_score); // set a variable
  }
}

export default function AddressInput({
    id,
    onAddressComplete,
  }) {

  //ADDRESS AUTOCOMPLETE
  let autocomplete
  const [address, setAddress] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const handleOnLoad = () => {

    if (window.google) {
      autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById(id),
        {
          types: ["geocode"],
        }
      )
      autocomplete.setFields(["address_components", "formatted_address"])
      autocomplete.addListener("place_changed", handlePlaceChange)
    }
  }

  const checkZipCode = async zipCode => {
    setIsLoading(true)
    try {
      const res = await verifyZipCode({ zip_code: zipCode })
      setIsLoading(false)
      return !!res.data.success
    } catch (e) {
      console.warn(e)
      setIsLoading(false)
    }
   }

  const handlePlaceChange = async () => {

    if (/\d/.test(document.getElementById(id).value) == true) {

      const { address_components, formatted_address } = autocomplete.getPlace()
      if (!(address_components && formatted_address)) {
        return
      }
      checkFormStatus(formatted_address)
      setAddress(formatted_address)
      const addressValues = parseGoogleAddressComponents(address_components)
      const isValid = await checkZipCode(addressValues.postal_code)
      return (
        onAddressComplete &&
        onAddressComplete({ formatted_address, isValid, ...addressValues })
      )
    }

  }
  
  const parseGoogleAddressComponents = arr => {
    const address = {}
    arr.forEach(add => {
      address[add.types[0]] = add.long_name
    })
    return address
  }

    //CHECK STATUS
    function checkFormStatus(address) {
    
      var gray = "w-full max-w-lg py-2 bg-transparent border-b border-gray-500 text-gray-500 outline-none placeholder-gray-500 text-xl font-light tracking-wide";
      var white = "w-full max-w-lg py-2 bg-transparent border-b border-white text-white outline-none placeholder-white text-xl font-light tracking-wide";

      if (address != '' && typeof address == 'string') {

          document.getElementById("spinner-form").hidden = false;

          //READ VALUES AND GENERATE REPORT LINK
          //useEffect(() => { 
            var property_value = sessionStorage.getItem('property-value-selector');
            var mortgage_balance = sessionStorage.getItem('mortgage-balance-selector')
            var income_level = sessionStorage.getItem('household-income-selector')
            var credit_score = sessionStorage.getItem('credit-score-selector')

            //alert (address + " " + property_value + " " + mortgage_balance + " " + income_level + " " + credit_score);
            var report_link = "/financial-calculator-report/" + "?formatted_address=" + address + "&property_value=" + property_value + "&mortgage_balance=" + mortgage_balance + "&income_level=" + income_level + "&credit_score=" + credit_score
            window.location.replace(report_link);
            //alert(report_link)
          
            //});
      }
    }

    //const formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',maximumFractionDigits: 0});
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      //formatter.format(max)

    //function formatterEmpty(){return}
    const formatter = typeof window !== "undefined" ? new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',maximumFractionDigits: 0}) : "";

    function sliderRange(parameter, min, max){

        document.getElementById(parameter + "-input").min=min;
        document.getElementById(parameter + "-input").max=max;

        var elem = document.getElementById(parameter + "-input");
        var rangeValue = function(){

            var newValue = elem.value;
            if (newValue == max) { 

              if (parameter == "credit-score") {
                document.getElementById(parameter + "-selector").innerHTML = max + ' or higher'
                sessionStorage.setItem(parameter + "-selector", newValue);
              } else {
              document.getElementById(parameter + "-selector").innerHTML = formatter.format(max) + ' or more'
              sessionStorage.setItem(parameter + "-selector", newValue); 
              }
            }

            else if ( newValue == min ) {

              if (parameter == "credit-score") {
                document.getElementById(parameter + "-selector").innerHTML = min + ' or lower'
                sessionStorage.setItem(parameter + "-selector", newValue);
              } else {
              document.getElementById(parameter + "-selector").innerHTML = formatter.format(min) + ' or less' 
              sessionStorage.setItem(parameter + "-selector", newValue); 
              }
            }
            else {
              if (parameter == "credit-score") {
                document.getElementById(parameter + "-selector").innerHTML = newValue;
                sessionStorage.setItem(parameter + "-selector", newValue);
              } else {
              document.getElementById(parameter + "-selector").innerHTML = formatter.format(newValue);
              sessionStorage.setItem(parameter + "-selector", newValue); 
              }
            }
        }
        elem.addEventListener("input", rangeValue);
      }

      //CHECK IF ADDRESS IS FULL
      const isAddressValid = () => {
        if (/\d/.test(document.getElementById(id).value) == false) {
          setAddress("");
          document.getElementById(id).placeholder = "Please enter full address";
        }
      };

  return (
        
      <m id="full-page-content">

      <form autoComplete="off" className="w-full flex justify-center mt-8 tracking-wide">

        <Script
          //url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAEUA8nRhSd10_ezuWEMEV5TwELbbuwxf4&libraries=places"
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAFpUvr6VeIKf--CTji6Y_Cd0odWV3u5DE&libraries=places"
          onLoad={handleOnLoad}
        />

        <input
          autoComplete="false"
          name="hidden"
          type="text"
          className="hidden"
        />

        <label htmlFor="address" className="hidden">
          Address
        </label>

        <div className="relative w-full" style={{minWidth: "100%", maxWidth: "100%",}}>

        <br></br>

        <div class="w-full max-w-lg py-2 text-gray-500 text-xl font-light text-left tracking-wide">
          Your Current Property Value
        </div>
        <div class="w-full max-w-lg py-2 text-white-500 text-xl font-light text-left tracking-wide" 
        id="property-value-selector">
          {//formatter.format(property_value)
          }
          {typeof window !== "undefined" ? formatter.format(property_value) : ""}
        </div>
        <div class="slider">
          <input class="slider"
            id="property-value-input"
            type="range"
            defaultValue={property_value}
            step="10000"
            min="50000"
            max="1500000"
            onChange={() => sliderRange("property-value", "50000", "1500000")} //parameter, min, max
            />
        </div>
       
        <br></br>

        <div class="w-full max-w-lg py-2 text-gray-500 text-xl font-light text-left tracking-wide">
          Your Current Mortgage Balance
        </div>
        <div class="w-full max-w-lg py-2 text-white-500 text-xl font-light text-left tracking-wide" 
        id="mortgage-balance-selector">
          {//formatter.format(mortgage_balance)
          }
          {typeof window !== "undefined" ? formatter.format(mortgage_balance) : ""}
        </div>

        <div class="slider">
          <input class="slider"
            id="mortgage-balance-input"
            type="range"
            defaultValue={mortgage_balance}
            step="10000"
            min="50000"
            max="1500000"
            onChange={() => sliderRange("mortgage-balance", "50000", "1500000")} //parameter, min, max
            />
        </div>
       
        <br></br>

        <div class="w-full max-w-lg py-2 text-gray-500 text-xl font-light text-left tracking-wide">
        Your Current Household Income
        </div>
        <div class="w-full max-w-lg py-2 text-white-500 text-xl font-light text-left tracking-wide" 
        id="household-income-selector">
          {//formatter.format(income_level)
          }
          {typeof window !== "undefined" ? formatter.format(income_level) : ""}
        </div>

        <div class="slider">
          <input class="slider"
            id="household-income-input"
            type="range"
            defaultValue={income_level}
            step="1000"
            min="50000"
            max="300000"
            onChange={() => sliderRange("household-income", "50000", "300000")} //parameter, min, max
            />
        </div>

        <br></br>

        <div class="w-full max-w-lg py-2 text-gray-500 text-xl font-light text-left tracking-wide">
          Your Credit Score
        </div>
        <div class="w-full max-w-lg py-2 text-white-500 text-xl font-light text-left tracking-wide" 
        id="credit-score-selector">
          {credit_score}
        </div>

        <div class="slider">
          <input class="slider"
            id="credit-score-input"
            type="range"
            defaultValue={credit_score}
            step="1"
            min="579"
            max="800"
            onChange={() => sliderRange("credit-score", "579", "800")} //parameter, min, max
            />
        </div>

          <br></br>
          <br></br>

          <input
            id={id}
            name="address"
            type="text"
            autoComplete="off"
            placeholder="Address Of Installation"
            className="w-full max-w-lg py-2 bg-transparent border-b border-white text-white outline-none placeholder-white text-xl font-light tracking-wide"
            //onChange={e => setAddress(e.target.value)}
            onChange={e => { setAddress(e.target.value); isAddressValid(); }}
            value={address}
          />
          
          <br></br>
          <br></br>

          <div id="spinner-form" className="relative w-full text-center overflow-hidden h-auto" hidden>
              <LoadingSpinner />
          </div>

        </div>

      </form>
      </m>
  )
}